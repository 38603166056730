body {
	min-height:100vh;
	display:flex;
	#root {
		flex-grow: 1;
		display:flex;
		flex-direction: column;
	}
}

.quiz-container {
	max-width: 500px;
	min-width: 250px;
	background: #ffffff;
	border-radius: 4px;
	margin-top: 100px;
	padding: 30px 60px;
  }
  
  .quiz-container .active-question-no {
	font-size: 32px;
	font-weight: 500;
	color: #800080;
  }
  
  .quiz-container .total-question {
	font-size: 16px;
	font-weight: 500;
	color: #e0dee3;
  }
  
  .quiz-container h2 {
	font-size: 20px;
	font-weight: 500;
	margin: 0;
  }
  
  .quiz-container ul {
	margin-top: 20px;
	margin-left: -40px;
  }
  
  .quiz-container ul li {
	text-decoration: none;
	list-style: none;
	color: #2d264b;
	font-size: 16px;
	background: #ffffff;
	border: 1px solid #eaeaea;
	border-radius: 16px;
	padding: 11px;
	margin-top: 15px;
	cursor: pointer;
  }
  
  .quiz-container ul .selected-answer {
	background: #ffd6ff;
	border: 1px solid #800080;
  }

  .quiz-container ul .wrong-answer {
	background: #e95247;
	border: 1px solid #e95247;
	color: #ffffff;
  }
  
  .quiz-container ul .right-answer {
	background: #3c9929;
	border: 1px solid #3c9929;
	color: #ffffff;
  }

  .quiz-container button {
    background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .end-game-btn {
    background: linear-gradient(90.04deg, #e95247 0.03%, #e95247 99.96%);
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    float: right;
    margin-bottom: 15px;
  }

  .buy-more-text {
    cursor: pointer;
  }
  
  .quiz-container button:disabled {
	background: #e7e8e9;
	color: #9fa3a9;
	cursor: not-allowed;
  }
  
  .flex-right {
	display: flex;
	justify-content: flex-end;
  }
  
  .result h3 {
	font-size: 24px;
	letter-spacing: 1.4px;
	text-align: center;
  }
  
  .result p {
	font-size: 16px;
	font-weight: 500;
  }
  
  .result p span {
	color: #800080;
	font-size: 22px;
  }

  .countdown-container{
    margin: 0 auto;
    button {
      background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%) !important;
    	border: none;
    }
}

.quiz-sidebar {
  .sidenav {
	height: 100%;
	width: 130px;
	position: fixed;
	z-index: 1;
    top: 150px;
	left: 0;
	background-color: #111;
	overflow-x: hidden;
	padding-top: 20px;
  }
  
  .sidenav li {
	padding: 16px 8px 16px 16px;
	text-decoration: none;
	font-size: 15px;
	color: #ffffff;
	display: block;
	border-bottom: 1px solid #cecece;
  }
  
  .sidenav li:hover {
	color: #f1f1f1;
  }

  .game-tokens {
	  font-size: 24px;
	  margin-left:15px;
  }

  .bid-size-dropdown {
	  background-color: #000;
	  border: 1px solid #fff;
	  border-radius: 5px;
	  margin-left: 0px;
  }

  .sound-icon {
	 cursor: pointer;
	 margin-left:15px;
  }
  
  .main {
	margin-left: 130px; /* Same as the width of the sidenav */
	font-size: 18px; /* Increased text to enable scrolling */
	padding: 0px 10px;
  }
  
  @media screen and (max-height: 450px) {
	.sidenav {padding-top: 15px;}
	.sidenav a {font-size: 18px;}
  }
}

.how-it-works-content {
	width: 600px !important;
}

.help-icon {
    border: 2px solid #000;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    margin-top: 38px;
    font-weight: 700;
    margin-left: -22px;
}

.help-icon:hover {
  background: #FFC000;
  color: #ffffff;
}

@keyframes blink {
    0% {
           background-color: rgba(255,0,0,1)
    }
    50% {
           background-color: rgba(255,0,0,0.5)
    }
    100% {
           background-color: rgba(255,0,0,1)
    }
}
@-webkit-keyframes blink {
    0% {
           background-color: rgba(255,0,0,1)
    }
    50% {
           background-color: rgba(255,0,0,0.5)
    }
    100% {
           background-color: rgba(255,0,0,1)
    }
}

@keyframes blinksuccess {
    0% {
           background-color: rgba(15, 139, 46, 1)
    }
    50% {
           background-color: rgba(15, 139, 46,0.5)
    }
    100% {
           background-color: rgba(15, 139, 46,1)
    }
}
@-webkit-keyframes blinksuccess {
    0% {
           background-color: rgba(15, 139, 46, 1)
    }
    50% {
           background-color: rgba(15, 139, 46,0.5)
    }
    100% {
           background-color: rgba(15, 139, 46,1)
    }
}
.game-token-removed {
	color: #ffffff;
	-moz-transition:all 0.5s ease-in-out;
    -webkit-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
    -moz-animation:blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation:blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation:blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation:blink normal 1.5s infinite ease-in-out;
    /* Opera */
}

.game-token-added {
	color: #ffffff;
	-moz-transition:all 0.5s ease-in-out;
    -webkit-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -ms-transition:all 0.5s ease-in-out;
    transition:all 0.5s ease-in-out;
    -moz-animation:blinksuccess normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation:blinksuccess normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation:blinksuccess normal 1.5s infinite ease-in-out;
    /* IE */
    animation:blinksuccess normal 1.5s infinite ease-in-out;
    /* Opera */
}

.topic_dropdown { 
  margin-top: 30px;
}

.my-profile-link-top {
  padding-top: 30px;
}

.dropdown-content-profile {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-profile a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content-profile a:hover {
  background-color: #ddd;
  color: black;
}

.my-profile-link-top:hover .dropdown-content-profile {
  display: block;
}

/**
* Responsive media queries
*/
/* Mobile Queries */
@media screen and (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: block;
  }

  .quiz-sidebar {
    display: none;
  }

  .select-topic-container {
    margin-top: 50px;
  }

  .quiz-container {
    margin-top: 15px;
  }

  .quiz-sidebar-mobile {
    display: block;
    .sidenav {
      li {
        display: inline-block;
        float: left;
        padding: 20px 10px;
        background: #000;
        color: #fff;
        height: 72px;
        border-left: 1px solid #fff;
      }
    }
  }
  .bid-size-dropdown {
    background-color: #000;
    border: 1px solid #fff;
  }

  .transaction-list {
    overflow-x: auto !important;
    table {
      width: 700px;
    }
  }


  #connect-bc-btn {
    font-size: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  #convert-btn {
    width: 180px;
  }
}

/* descktop Queries */
@media screen and (min-width: 992px) {
  .desktop-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }

  .quiz-sidebar-mobile {
    display: none;
  }
}

#hamburger-icon {
  margin: auto 0;
  position: absolute;
  right: 30px;
  cursor: pointer;
  top: 55px;
}

#hamburger-icon div {
  width: 35px;
  height: 3px;
  background-color: #000000;
  margin: 6px 0;
  transition: 0.4s;
}

.open-submenu .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open-submenu .bar2 {
  opacity: 0;
}

.open-submenu .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.open-submenu .mobile-menu {
  display: block;
  width: 200px;
  z-index: 999;
  position: absolute;
  right: 0;
  top: auto;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
  text-align: center;
}

.mobile-menu li {
  padding: 10px 5px;
  margin-left: 10px; 
  background-color: #fff;
  border: 1px solid #000000;
  border-bottom: 0px;
}
.mobile-menu li:last-child {
  border-bottom: 1px solid #000000;
}

.clear-selection-btn {
  cursor: pointer;
  font-size: 14px;
  margin-left: 2px;
}

.tab-buttons{
  margin-bottom:50px;
  button{
    margin-right:15px;
    font-size:20px;
    background:transparent;
    border:none;
    outline:none;
    padding:10px 20px ;
    cursor:pointer;
    color:rgba(0,0,0,.4);
    transition:all ease-in-out .2s;
    border-bottom:2px solid transparent;
    
  }

  .active{
    border-bottom:2px solid rgb(16,185,129); 
    color:black;
  }
}

.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.withdraw-token-field {
  width: 224px;
}

.accordion {
  label:after {
    content: '+';
    position: absolute;
    right: 1em;
    color: #fff;
  }

  input:checked + label:after {
    content: '-';
    line-height: .8em;
  }

  .accordion__content{
    max-height: 0em;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
  }
  input[name='panel']:checked ~ .accordion__content {
    /* Get this as close to what height you expect */
    max-height: 50em;
  }
}

.link-cursor {
  cursor:pointer;
}
